import React from 'react';
import Modal from 'react-modal';

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    width: '80%',
    maxWidth: '800px',
    background: '#1b062f',
    textAlign: 'center'
  },
  overlay: {
    background: "rgba(11, 2, 18, 0.75)"
  }
};

const SpotifyModal = ({ isOpen, onRequestClose, embedUri }) => {
  const embedUrl = `https://open.spotify.com/embed/${embedUri}`;
  
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      style={customStyles}
      contentLabel="Spotify Podcast"
      ariaHideApp={false}
    >
      <div className='w-full text-right pb-[10px]'>
        <button onClick={onRequestClose} className="modal-close-button text-right">
          <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24"><path fill="currentColor" d="M6.4 19L5 17.6l5.6-5.6L5 6.4L6.4 5l5.6 5.6L17.6 5L19 6.4L13.4 12l5.6 5.6l-1.4 1.4l-5.6-5.6z"/></svg>
        </button>
      </div>
      <div>
        <iframe
          src={embedUrl}
          width="100%"
          height="380"
          frameBorder="0"
          allowTransparency="true"
          allow="encrypted-media"
          title="Spotify Player"
        ></iframe>
      </div>
    </Modal>
  );
};

export default SpotifyModal;
